/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Stack, Banner, BodyText, Button, Card} from '@servicetitan/design-system';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code",
    h3: "h3",
    strong: "strong",
    hr: "hr",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "hierarchy",
    style: {
      position: "relative"
    }
  }, "Hierarchy", React.createElement(_components.a, {
    href: "#hierarchy",
    "aria-label": "hierarchy permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Strict order of buttons is not necessary. Visual hierarchy between Buttons is the important goal."), "\n", React.createElement(_components.h2, {
    id: "primary-actions",
    style: {
      position: "relative"
    }
  }, "Primary Actions", React.createElement(_components.a, {
    href: "#primary-actions",
    "aria-label": "primary actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "As a rule, only a single primary action should be present at a time to draw the user through a flow."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Button primary>Save Invoice</Button>\n")), "\n", React.createElement(_components.p, null, "Destructive primary actions use a ", React.createElement(_components.a, {
    href: "/foundations/color/#red"
  }, "negative color"), ". Clicking this should trigger a confirmation (usually as a ", React.createElement(_components.a, {
    href: "/components/dialog/"
  }, "Dialog"), ") before proceeding with the action."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Button negative>Delete Invoice</Button>\n")), "\n", React.createElement(_components.h2, {
    id: "secondary-actions",
    style: {
      position: "relative"
    }
  }, "Secondary Actions", React.createElement(_components.a, {
    href: "#secondary-actions",
    "aria-label": "secondary actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When multiple actions surface information or start a workflow, the secondary variant can be used. It also pairs well with a primary action."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Button primary outline>Add Contractor</Button>\n")), "\n", React.createElement(_components.p, null, "A negative equivalent also exists. It is used when a destructive action is less prominent, usually paired with other buttons."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Button negative outline>Remove Contractor</Button>\n")), "\n", React.createElement(_components.h2, {
    id: "tertiary-actions",
    style: {
      position: "relative"
    }
  }, "Tertiary Actions", React.createElement(_components.a, {
    href: "#tertiary-actions",
    "aria-label": "tertiary actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "For less pronounced, common actions throughout a page."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Button>Copy Invoice Number</Button>\n")), "\n", React.createElement(_components.p, null, "An alternative tertiary style also exists. It's used when solid gray is unable to visually work, such as on non-white backgrounds, or pairing with a secondary action."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Button outline>Copy Invoice Number</Button>\n")), "\n", React.createElement(_components.h2, {
    id: "subtle-actions",
    style: {
      position: "relative"
    }
  }, "Subtle Actions", React.createElement(_components.a, {
    href: "#subtle-actions",
    "aria-label": "subtle actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "On data-heavy UIs, the subtle style can be used in large quantities without adding heavy visuals."), "\n", React.createElement(_components.h3, {
    id: "text-based-subtle-actions",
    style: {
      position: "relative"
    }
  }, "Text-based Subtle Actions", React.createElement(_components.a, {
    href: "#text-based-subtle-actions",
    "aria-label": "text based subtle actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When the button is Text, the blue variant is preferred over gray to preserve the Button's signifier to the user."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Button fill=\"subtle\" primary>Edit</Button>\n")), "\n", React.createElement(_components.h3, {
    id: "icon-based-subtle-actions",
    style: {
      position: "relative"
    }
  }, "Icon-based Subtle Actions", React.createElement(_components.a, {
    href: "#icon-based-subtle-actions",
    "aria-label": "icon based subtle actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "For Icons, the gray variant is preferred if the icon is understandable to end users."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Tooltip text=\"Edit\" portal><Button fill=\"subtle\" iconName=\"edit\" /></Tooltip>\n")), "\n", React.createElement(_components.h2, {
    id: "pairing-guidelines",
    style: {
      position: "relative"
    }
  }, "Pairing Guidelines", React.createElement(_components.a, {
    href: "#pairing-guidelines",
    "aria-label": "pairing guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "See the ", React.createElement(_components.a, {
    href: "/components/button-group"
  }, "Button Group"), " component guidelines on applying button hierarchy to multiple buttons."), "\n", React.createElement(_components.h1, {
    id: "alignment",
    style: {
      position: "relative"
    }
  }, "Alignment", React.createElement(_components.a, {
    href: "#alignment",
    "aria-label": "alignment permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "left-aligned",
    style: {
      position: "relative"
    }
  }, "Left Aligned", React.createElement(_components.a, {
    href: "#left-aligned",
    "aria-label": "left aligned permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Left-aligned buttons are recommended for single page forms and focused tasks. The buttons are ordered from left to right by importance."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Headline el=\"div\" className=\"m-b-4\">Add Contact</Headline>\n\t<Form.Group widths=\"equal\">\n\t\t<Form.Input label=\"First Name\" placeholder=\"Leia\" />\n\t\t<Form.Input label=\"Last Name\" placeholder=\"Organa\" />\n\t</Form.Group>\n\t<Form.Select label=\"Home Planet\" placeholder=\"Choose Planet\" options={[\n\t\t{key:1, value: 1, text: 'Alderaan'},\n\t\t{key:2, value: 2, text: 'Bespin'},\n\t\t{key:3, value: 3, text: 'Coruscant'},\n\t\t{key:4, value: 4, text: 'Dagobah'},\n\t\t{key:5, value: 5, text: 'Hoth'},\n\t\t{key:6, value: 6, text: 'Kashyyyk'},\n\t\t{key:7, value: 7, text: 'Naboo',},\n\t\t{key:8, value: 8, text: 'Tatooine'},\n\t\t{key:9, value: 9, text: 'Yavin'},\n\t]} />\n\t<Form.Group>\n\t\t<Form.Button primary>Save Contact</Form.Button>\n\t\t<Form.Button>Cancel</Form.Button>\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.h2, {
    id: "right-aligned",
    style: {
      position: "relative"
    }
  }, "Right Aligned", React.createElement(_components.a, {
    href: "#right-aligned",
    "aria-label": "right aligned permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Buttons in page headers and footers prompt a user to move through a sequence of screens are right aligned to visually support navigation. The buttons are ordered from right to left by importance."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack alignItems=\"center\" justifyContent=\"space-between\">\n\t<Headline size=\"small\" className=\"m-0\">Invoices</Headline>\n\t<ButtonGroup>\n\t\t<Button primary outline>Create Estimate</Button>\n\t\t<Button primary>Create Invoice</Button>\n\t</ButtonGroup>\n</Stack>\n")), "\n", React.createElement(_components.p, null, "Within Overlays, like ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/modal/"
  }, "Modals")), ", actions are right-aligned as well to encourage forward progress through a workflow."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 200\n---\n<Modal\n\topen\n\tfooter={<ButtonGroup>\n\t\t<Button>Cancel</Button>\n\t\t<Button primary>Save Invoice</Button>\n\t</ButtonGroup>}\n\tportal={false}\n/>\n")), "\n", React.createElement(_components.h2, {
    id: "centered",
    style: {
      position: "relative"
    }
  }, "Centered", React.createElement(_components.a, {
    href: "#centered",
    "aria-label": "centered permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Centered buttons are used when the content is shown in a small, isolated view. These should be used sparingly and are most commonly within overlay-based components such as ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/modal/"
  }, "Modals")), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 200\n---\n<Modal\n\topen\n\tfooter={<Button>Close</Button>}\n\tfooterAlign=\"center\"\n\tportal={false}\n/>\n")), "\n", React.createElement(_components.h1, {
    id: "variations",
    style: {
      position: "relative"
    }
  }, "Variations", React.createElement(_components.a, {
    href: "#variations",
    "aria-label": "variations permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "colors",
    style: {
      position: "relative"
    }
  }, "Colors", React.createElement(_components.a, {
    href: "#colors",
    "aria-label": "colors permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "There are three color variations for buttons. Blues and reds tend to be more prominent in a neutral-dominant UI, while grays allow for deemphasized actions."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack spacing={3} justifyContent=\"space-around\" className=\"w-100\" wrap=\"wrap\">\n\t<Stack direction=\"column\" spacing={3} className=\"p-b-2\">\n\t\t<Button full primary>Primary Solid</Button>\n\t\t<Button full outline primary>Secondary Outline</Button>\n\t\t<Button full fill='subtle' primary>Subtle Blue</Button>\n\t</Stack>\n\t<Stack direction=\"column\" spacing={3} className=\"p-b-2\">\n\t\t<Button full>Tertiary Solid</Button>\n\t\t<Button full outline>Tertiary Outline</Button>\n\t\t<Button full fill='subtle'>Subtle Gray</Button>\n\t</Stack>\n\t<Stack direction=\"column\" spacing={3} className=\"p-b-2\">\n\t\t<Button full negative>Negative Solid</Button>\n\t\t<Button full outline negative>Secondary Negative</Button>\n\t\t<Button full fill='subtle' negative>Subtle Negative</Button>\n\t</Stack>\n</Stack>\n")), "\n", React.createElement(_components.h2, {
    id: "fills",
    style: {
      position: "relative"
    }
  }, "Fills", React.createElement(_components.a, {
    href: "#fills",
    "aria-label": "fills permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "There are three fills a button can have. A ", React.createElement(_components.strong, null, "solid fill"), " is useful for bold and important actions. An ", React.createElement(_components.strong, null, "outline fill"), " can be used for secondary actions and looks good next to a solid button and stand out on colored backgrounds. A ", React.createElement(_components.strong, null, "subtle fill"), " is useful for actions that don't need to call a lot of attention to."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack spacing={5} justifyContent=\"space-around\" wrap=\"wrap\">\n\t<Button primary>Solid Button</Button>\n\t<Button outline primary>Outline Button</Button>\n\t<Button fill='subtle' primary>Text Button</Button>\n</Stack>\n")), "\n", React.createElement(_components.h2, {
    id: "disabled",
    style: {
      position: "relative"
    }
  }, "Disabled", React.createElement(_components.a, {
    href: "#disabled",
    "aria-label": "disabled permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "We have two separate ways of disabling buttons. A ", React.createElement(_components.code, null, "disabled"), " attribute will prevent clicks and change the button to a grey style. An ", React.createElement(_components.code, null, "inactive"), " attribute will prevent clicks but keeps the button looking the same. This is useful for preventing stray clicks while an API call is running or showing an outlined-to-active state change."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<Button disabled>Disabled Button</Button>\n\t<br/><br/>\n\t<Button inactive>Unclickable Button</Button>\n</div>\n")), "\n", React.createElement(_components.h2, {
    id: "sizes",
    style: {
      position: "relative"
    }
  }, "Sizes", React.createElement(_components.a, {
    href: "#sizes",
    "aria-label": "sizes permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "We support three sizes of buttons that can be set using the ", React.createElement(_components.code, null, "size"), " prop. Small buttons are useful for secondary or ancillary actions. Large buttons are great for important call-to-actions and helping unstick a user in a setup flow."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<Button xsmall>X-Small Button</Button>\n\t<br/><br/>\n\t<Button small>Small Button</Button>\n\t<br/><br/>\n\t<Button>Normal Button</Button>\n\t<br/><br/>\n\t<Button large>Large Call-to-Action Button</Button>\n</div>\n")), "\n", React.createElement(_components.h2, {
    id: "widths",
    style: {
      position: "relative"
    }
  }, "Widths", React.createElement(_components.a, {
    href: "#widths",
    "aria-label": "widths permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The default width of buttons has a consistent amount of padding between the text and the ends of the button. This can be overridden with the ", React.createElement(_components.code, null, "width"), " property, either setting it to a specific size or making the button full width.\nSpecific sized buttons should only be used when buttons are next to each other or in a column and have variable text lengths. Keeping them the same width will make the buttons feel intentional. Setting a width for every button is not recommended."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<Button>Fluid Width Button</Button>\n\t<br/><br/>\n\t<Button width=\"300px\">Specific Width Button</Button>\n\t<br/><br/>\n\t<Button full>Full Width Button</Button>\n</div>\n")), "\n", React.createElement(_components.h2, {
    id: "with-icon",
    style: {
      position: "relative"
    }
  }, "with Icon", React.createElement(_components.a, {
    href: "#with-icon",
    "aria-label": "with icon permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Buttons can have an icon to the left or right of the action label."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<ButtonGroup>\n\t<Button iconName=\"add\">Add new item</Button>\n\t<Button iconName=\"expand_more\" iconPosition=\"right\">Menu</Button>\n\t<Button icon={<span>&trade;</span>} iconPosition=\"right\">Custom Icon</Button>\n</ButtonGroup>\n")), "\n", React.createElement(_components.h2, {
    id: "button-icons",
    style: {
      position: "relative"
    }
  }, "Button Icons", React.createElement(_components.a, {
    href: "#button-icons",
    "aria-label": "button icons permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When pairing an Icon and an action, an Icon-only Button is used to control the interaction. To add clarity to the action, Button Icons should be paired with ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/tooltip/"
  }, "Tooltips")), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<ButtonGroup>\n\t<Tooltip el=\"div\" text=\"Add New Widget\" portal><Button iconName=\"add\"/></Tooltip>\n\t<Tooltip el=\"div\" text=\"Edit Invoice\" portal><Button iconName=\"edit\"/></Tooltip>\n\t<Tooltip el=\"div\" text=\"Check Availability\" direction=\"b\" portal><Button iconName=\"event\" /></Tooltip>\n\t<Tooltip el=\"div\" text=\"More Actions\" direction=\"t\" portal><Button iconName=\"more_vert\" /></Tooltip>\n</ButtonGroup>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={5}>\n\t{([state, setState]) => (\n\t\t<Form>\n\t\t\t<Stack spacing=\"1\">\n\t\t\t\t<Tooltip el=\"div\" text=\"Remove Widgets\">\n\t\t\t\t\t<Button onClick={() => setState(state - 1)} iconName=\"remove\" />\n\t\t\t\t</Tooltip>\n\t\t\t\t<Input style={{width: '50px'}} value={state} />\n\t\t\t\t<Tooltip el=\"div\" text=\"Add More Widgets\" direction=\"t\">\n\t\t\t\t\t<Button onClick={() => setState(state + 1)} iconName=\"add\" primary />\n\t\t\t\t</Tooltip>\n\t\t\t</Stack>\n\t\t\t<Stack spacing=\"1\" className=\"m-t-3\">\n\t\t\t\t<Tooltip el=\"div\" text=\"Remove Widgets\">\n\t\t\t\t\t<Button onClick={() => setState(state - 1)} iconName=\"remove\" small />\n\t\t\t\t</Tooltip>\n\t\t\t\t<Input small style={{width: '50px'}} value={state} />\n\t\t\t\t<Tooltip el=\"div\" text=\"Add More Widgets\" direction=\"t\">\n\t\t\t\t\t<Button onClick={() => setState(state + 1)} iconName=\"add\" primary small />\n\t\t\t\t</Tooltip>\n\t\t\t</Stack>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={1}>\n\t{([state, setState]) => {\n\t\tconst options = [\n\t\t\t{key: 1, value: 1, text: 'Australia'},\n\t\t\t{key: 2, value: 2, text: 'Argentina'},\n\t\t\t{key: 3, value: 3, text: 'Bolivia'},\n\t\t];\n\t\treturn (\n\t\t\t<Form>\n\t\t\t\t<Stack spacing=\"1\">\n\t\t\t\t\t<Select options={options} value={state} onChange={(e, v) => setState(v.value)} />\n\t\t\t\t\t<Tooltip el=\"div\" text=\"Confirm\" direction=\"b\"><Button iconName=\"check\" primary/></Tooltip>\n\t\t\t\t\t<Tooltip el=\"div\" text=\"Cancel\" direction=\"bl\"><Button iconName=\"close\"/></Tooltip>\n\t\t\t\t</Stack>\n\t\t\t\t<Stack spacing=\"1\" className=\"m-t-3\">\n\t\t\t\t\t<Select options={options} value={state} onChange={(e, v) => setState(v.value)} small />\n\t\t\t\t\t<Tooltip el=\"div\" text=\"Confirm\" direction=\"b\"><Button small iconName=\"check\" primary/></Tooltip>\n\t\t\t\t\t<Tooltip el=\"div\" text=\"Cancel\" direction=\"bl\"><Button small iconName=\"close\"/></Tooltip>\n\t\t\t\t</Stack>\n\t\t\t</Form>\n\t\t)\n\t}}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "loading",
    style: {
      position: "relative"
    }
  }, "Loading", React.createElement(_components.a, {
    href: "#loading",
    "aria-label": "loading permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Add the ", React.createElement(_components.code, null, "loading"), " prop to the button and it disables and gives a loading animation. Useful for preventing double submissions on forms. The button width will not change."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<Button primary loading>Button</Button>\n\t<br/><br/>\n\t<Button outline primary loading>Button</Button>\n\t<br/><br/>\n\t<Button fill='subtle' primary loading>Button</Button>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst LoadingButton = (props) => {\n\tconst { timeout = 2000 } = props;\n\tconst [loading, setLoading] = React.useState(false);\n\n\tconst onClickHandler = () => {\n\t\tsetLoading(true);\n\t\tsetTimeout(() => setLoading(false), timeout);\n\t}\n\n\treturn <Button onClick={onClickHandler} loading={loading} {...props} />;\n}\n\nrender (\n\t<div>\n\t\t<LoadingButton primary>Click Me</LoadingButton>\n\t\t<br/><br/>\n\t\t<LoadingButton primary timeout={3000}>A Longer Example</LoadingButton>\n\t\t<br/><br/>\n\t\t<LoadingButton primary full timeout={5000}>A Really Long Example</LoadingButton>\n\t</div>\n)\n")), "\n", React.createElement(_components.h2, {
    id: "selected",
    style: {
      position: "relative"
    }
  }, "Selected", React.createElement(_components.a, {
    href: "#selected",
    "aria-label": "selected permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Use the selected state when a Button needs to visually appear active, such as when an ", React.createElement(_components.a, {
    href: "/components/action-menu/"
  }, "Action Menu"), " associated with the Button is open."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack spacing={3} justifyContent=\"space-around\" className=\"w-100\" wrap=\"wrap\">\n    <Stack direction=\"column\" spacing={3} className=\"p-b-2\">\n\t\t<ButtonGroup attached>\n\t\t\t<Button primary>Refresh</Button>\n\t\t\t<Button primary iconName=\"expand_more\" selected />\n\t\t</ButtonGroup>\n\t\t<ButtonGroup attached>\n\t\t\t<Button primary outline>Refresh</Button>\n\t\t\t<Button primary outline iconName=\"expand_more\" selected />\n\t\t</ButtonGroup>\n\t\t<ButtonGroup attached>\n\t\t\t<Button primary fill='subtle'>Refresh</Button>\n\t\t\t<Button primary fill='subtle' iconName=\"expand_more\" selected />\n\t\t</ButtonGroup>\n    </Stack>\n    <Stack direction=\"column\" spacing={3} className=\"p-b-2\">\n\t\t<ButtonGroup attached>\n\t\t\t<Button>Refresh</Button>\n\t\t\t<Button iconName=\"expand_more\" selected />\n\t\t</ButtonGroup>\n\t\t<ButtonGroup attached>\n\t\t\t<Button outline>Refresh</Button>\n\t\t\t<Button outline iconName=\"expand_more\" selected />\n\t\t</ButtonGroup>\n\t\t<ButtonGroup attached>\n\t\t\t<Button fill='subtle'>Refresh</Button>\n\t\t\t<Button fill='subtle' iconName=\"expand_more\" selected />\n\t\t</ButtonGroup>\n    </Stack>\n\n    <Stack direction=\"column\" spacing={3} className=\"p-b-2\">\n\t\t<ButtonGroup attached>\n\t\t\t<Button negative>Refresh</Button>\n\t\t\t<Button negative iconName=\"expand_more\" selected />\n\t\t</ButtonGroup>\n\t\t<ButtonGroup attached>\n\t\t\t<Button negative outline>Refresh</Button>\n\t\t\t<Button negative outline iconName=\"expand_more\" selected />\n\t\t</ButtonGroup>\n\t\t<ButtonGroup attached>\n\t\t\t<Button negative fill='subtle'>Refresh</Button>\n\t\t\t<Button negative fill='subtle' iconName=\"expand_more\" selected />\n\t\t</ButtonGroup>\n    </Stack>\n</Stack>\n")), "\n", React.createElement(_components.h1, {
    id: "content-guidelines",
    style: {
      position: "relative"
    }
  }, "Content Guidelines", React.createElement(_components.a, {
    href: "#content-guidelines",
    "aria-label": "content guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "button-name-should-describe-what-it-does",
    style: {
      position: "relative"
    }
  }, "Button name should describe what it does", React.createElement(_components.a, {
    href: "#button-name-should-describe-what-it-does",
    "aria-label": "button name should describe what it does permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A user should be able to use the button name to predict what will happen when they click it."), "\n", React.createElement(_components.p, null, "Buttons should action-oriented, pairing a verb and a supporting noun, and be 2 to 3 words long. Common actions like “Save,” “Close,” “Cancel,” or “OK” don’t require a supporting noun."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Button, {
    size: "small"
  }, "Merge Accounts"), React.createElement(Button, {
    size: "small"
  }, "Save"), React.createElement(Button, {
    size: "small"
  }, "View Settings"))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Button, {
    size: "small"
  }, "Account Merge"), React.createElement(Button, {
    size: "small"
  }, "Save to Database"), React.createElement(Button, {
    size: "small"
  }, "Settings"))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "be-concise-and-consistent",
    style: {
      position: "relative"
    }
  }, "Be concise and consistent", React.createElement(_components.a, {
    href: "#be-concise-and-consistent",
    "aria-label": "be concise and consistent permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Avoid unnecessary words and articles such as the, an, or a. Never include punctuation in button text and avoid button text that requires punctuation. Always write button text in title case. Capitalize the first word, the last word, and all major words in between. Never use emoji or exclamation points."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Button, {
    size: "small"
  }, "Add Menu Item"), React.createElement(Button, {
    size: "small"
  }, "Done"))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Button, {
    size: "small"
  }, "Add a menu item"), React.createElement(Button, {
    size: "small"
  }, "Yes, I'm Done!"))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "button-name-should-match-destination",
    style: {
      position: "relative"
    }
  }, "Button name should match destination", React.createElement(_components.a, {
    href: "#button-name-should-match-destination",
    "aria-label": "button name should match destination permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When a button opens a modal or takeover, the destination’s title should match the button text."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(BodyText, null, "Button labeled Add Transaction opens a modal titled “Add transaction”"))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(BodyText, null, "Button labeled Add Transaction opens a modal titled “Create new invoice”."))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h1, {
    id: "technical-examples",
    style: {
      position: "relative"
    }
  }, "Technical Examples", React.createElement(_components.a, {
    href: "#technical-examples",
    "aria-label": "technical examples permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "click-action",
    style: {
      position: "relative"
    }
  }, "Click Action", React.createElement(_components.a, {
    href: "#click-action",
    "aria-label": "click action permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "onClick"), " prop to pass a function to the button."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Button onClick={() => alert('Clicked!')}>Button</Button>\n")), "\n", React.createElement(_components.h2, {
    id: "linked",
    style: {
      position: "relative"
    }
  }, "Linked", React.createElement(_components.a, {
    href: "#linked",
    "aria-label": "linked permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The standard button component uses a ", React.createElement(_components.code, null, "span"), " element because most buttons will be passed a function. If you pass the button a ", React.createElement(_components.code, null, "href"), " prop, it will become an ", React.createElement(_components.code, null, "a"), " element and click through to the url."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Button href=\"//servicetitan.com\">Link Button</Button>\n")), "\n", React.createElement(_components.h2, {
    id: "action-menu-dropdown",
    style: {
      position: "relative"
    }
  }, "Action Menu (Dropdown)", React.createElement(_components.a, {
    href: "#action-menu-dropdown",
    "aria-label": "action menu dropdown permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "You can pass ", React.createElement(_components.code, null, "Button"), " through the ", React.createElement(_components.code, null, "trigger"), " prop of the ", React.createElement(_components.code, null, "ActionMenu"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={false}>\n    {([open, setOpen]) => {\n\t\tconst handleClick = () => setOpen(!open);\n\t\tconst handleClickOutside = () => setOpen(false);\n\n        return (\n\t\t\t<ActionMenu\n\t\t\t\ttrigger={<Button onClick={handleClick} small iconName=\"expand_more\" iconPosition=\"right\">Use the force</Button>}\n\t\t\t\tsharp={false}\n\t\t\t\twidth=\"auto\"\n\t\t\t\tdirection=\"br\"\n\t\t\t\topen={open}\n\t\t\t\tonClickOutside={handleClickOutside}\n\t\t\t>\n\t\t\t\t<ActionMenu.Item>Precognition</ActionMenu.Item>\n\t\t\t\t<ActionMenu.Item>Psychometry</ActionMenu.Item>\n\t\t\t\t<ActionMenu.Item>Telepathy</ActionMenu.Item>\n\t\t\t\t<ActionMenu.Item>Shadow Vision</ActionMenu.Item>\n\t\t\t\t<ActionMenu.Item>Levitation</ActionMenu.Item>\n\t\t\t</ActionMenu>\n        )\n    }}\n</State>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use the same button sizes within an action area"), "\n", React.createElement(_components.li, null, "Prioritize the most important actions. Too many calls to action can cause confusion and make users unsure of what to do next."), "\n", React.createElement(_components.li, null, "Using several styles on one page may be confusing to user. Try to avoid using more than 3 styles on a screen."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-components",
    style: {
      position: "relative"
    }
  }, "Related Components", React.createElement(_components.a, {
    href: "#related-components",
    "aria-label": "related components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "For a text button inline in a paragraph, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/link/"
  }, "Link")), "."), "\n", React.createElement(_components.li, null, "To group Buttons together, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/button-group/"
  }, "Button Group")), "."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { Button } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
